import {
  STATUS_ACCESS_TO_RECORDS,
  STATUS_AUTHORITY_ANSWERED,
  STATUS_CHECKED,
  STATUS_AUTHORIZED,
  STATUS_INSURANCE_COVERAGE_REQUEST,
  STATUS_COMPLETED,
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_DECISION_MADE,
  STATUS_DOCUMENTS_COMPLETE,
  STATUS_EXTRAJUDICIAL_PAID,
  STATUS_OPINION_SUBMITTED,
  STATUS_POWER_OF_ATTORNEY_GRANTED,
  STATUS_TRIAL,
  STATUS_TRIAL_DATE_SET,
  STATUS_TRIAL_PAID,
} from "./leadStatus";
import moment from "moment";
import Button from "@mui/material/Button";
import { HashLink } from "react-router-hash-link";
import React from "react";
import { getCaseLink, getProductData } from "../productService";

const getContent = (traffic) => {
  let nextStepContent = {
    header: "",
    text: "",
  };

  switch (traffic.leadStatus) {
    case STATUS_CREATED:
      nextStepContent.header = "In nur 5 Minuten Ihre Möglichkeiten prüfen lassen";
      nextStepContent.text = "Es fehlen nur wenige Angaben, um einschätzen zu können, ob gegen den Vorwurf vorgegangen werden kann.";
      break;
    case STATUS_DATA_COMPLETED:
      nextStepContent.header = "Unterlagen prüfen lassen";
      nextStepContent.text =
        "Laden Sie jetzt alle Dokumente hoch, die Ihnen zu dem Vorwurf vorliegen, damit schnellstmöglich alle weiteren Schritte eingeleitet werden können.";
      break;
    case STATUS_DOCUMENTS_COMPLETE:
      nextStepContent.header = "Jetzt professionell unterstützen lassen!";
      nextStepContent.text = "Ihre Unterlagen sind eingegangen. Damit wir uns für Sie einsetzen dürfen, wird Ihre Vollmacht benötigt.";
      break;
    case STATUS_POWER_OF_ATTORNEY_GRANTED:
      if (traffic.paymentType === "private" && traffic.paidDate === null) {
        nextStepContent.header = "Jetzt außergerichtliche Vertretung bezahlen";
        nextStepContent.text = "";
        break;
      }
      if (!traffic.fineNoticeReceivedDate) {
        nextStepContent.header = "Warten auf den Bußgeldbescheid";
        nextStepContent.text =
          "Wir bestellen uns für Sie bei der Behörde als Rechtsbeistand und warten dann auf die Zustellung des Bußgeldbescheids durch die Behörde. Dies kann einige Zeit dauern. Wenn Sie den Bescheid erhalten, können Sie ihn einfach hier hochladen";
        break;
      }
      nextStepContent.header = "Ihr Fall ist in Bearbeitung";
      nextStepContent.text =
        "Wir melden uns nun umgehend bei Ihnen, um das weitere Vorgehen zu besprechen. Im nächsten Schritt werden wir Akteneinsicht zu Ihrem Fall einfordern.";
      break;
    case STATUS_CHECKED:
    case STATUS_AUTHORIZED:
    case STATUS_INSURANCE_COVERAGE_REQUEST:
      if (!traffic.fineNoticeReceivedDate) {
        nextStepContent.header = "Warten auf den Bußgeldbescheid";
        nextStepContent.text =
          "Wir bestellen uns für Sie bei der Behörde als Rechtsbeistand und warten dann auf die Zustellung des Bußgeldbescheids durch die Behörde. Dies kann einige Zeit dauern. Wenn Sie den Bescheid erhalten, können Sie ihn einfach hier hochladen";
        break;
      }

      nextStepContent.header = "Ihre Angaben wurden geprüft und es wird Akteneinsicht angefordert!";
      nextStepContent.text = "Die Akteneinsicht wird nun von uns angefordert. Sie werden umgehend benachrichtigt, sobald die Akten vorliegen.";
      break;
    case STATUS_ACCESS_TO_RECORDS:
    case STATUS_EXTRAJUDICIAL_PAID:
      if (traffic.investigation.assessment === "revoke" && traffic.customerAppealRevocationApproved === null) {
        nextStepContent.header = "Rücknahme des Einspruchs bestätigen";
        nextStepContent.text =
          "Die Prüfung Ihrer Ermittlungsakte hat ergeben, dass keine Aussicht auf eine erfolgreiche Verteidigung gegen den Bußgeldbescheid besteht. Wir müssen Ihnen daher empfehlen, den Einspruch zurückzunehmen. Wenn Sie damit einverstanden sind, bestätigen Sie dies bitte kurz hier:";
      } else {
        nextStepContent.header = "Es wurde Akteneinsicht angefordert!";
        nextStepContent.text =
          "Es kann mehrere Wochen dauern, bis uns die Behörde die Akte zukommen lässt. Sobald die Akte vorliegt, wird diese umgehend durch uns geprüft und dann das weitere Vorgehen mit Ihnen abgestimmt.";
      }
      break;
    case STATUS_OPINION_SUBMITTED:
      nextStepContent.header = "Stellungnahme wurde übermittelt!";
      nextStepContent.text =
        "Bis sich die Behörde zu Ihrem Fall äußert, kann es leider erfahrungsgemäß einige Wochen dauern. Sobald uns eine Rückmeldung vorliegt, melden wir uns direkt bei Ihnen!";
      break;

    case STATUS_AUTHORITY_ANSWERED:
      if (traffic.paymentType !== "private" || traffic.authorityDecision !== "court") {
        nextStepContent.header = "Antwort von Behörde erhalten!";
        nextStepContent.text =
          "Wir haben die Antwort von der Behörde erhalten. Das entsprechende Schreiben finden Sie unter “Meine Fälle”. Wir werden uns nun zeitnah bei Ihnen melden, um das weitere Vorgehen zu besprechen.";
        break;
      }
      nextStepContent.header = "Weiteres Vorgehen festlegen";
      if (traffic.trialWanted === null) {
        nextStepContent.text =
          "Im nächsten Schritt wird ihr Fall vor Gericht verhandelt. Bitte teilen Sie uns mit, wie Sie mit dem Fall weiter verfahren möchten.";
        break;
      } else {
        nextStepContent.text =
          "Wir haben Ihre Rückmeldung erhalten und werden Ihren Fall entsprechend weiter bearbeiten. Sie werden hier wie gewohnt zeitnah über das weitere Vorgehen informiert.";
        break;
      }
    case STATUS_TRIAL:
      nextStepContent.header = "Das gerichtliche Verfahren läuft";
      nextStepContent.text =
        "Das Gericht hat sich zu Ihrem Fall geäußert. Das Schreiben vom Gericht finden Sie unter “Meine Fälle”. Somit geht Ihr Fall nun in das gerichtliche Verfahren über.";
      break;
    case STATUS_TRIAL_DATE_SET:
    case STATUS_TRIAL_PAID:
      nextStepContent.header = "Der Gerichtstermin steht fest";
      if (!!traffic.trialCourtAppointment?.courtDate?.appointmentDateTime) {
        nextStepContent.text = `Das Gericht hat den ${moment(traffic.trialCourtAppointment?.courtDate.appointmentDateTime).format(
          "l"
        )} als Termin für Ihr Verfahren festgesetzt. Weitere Informationen können Sie dem Schreiben entnehmen, das Sie unter “Meine Fälle” finden. Melden Sie sich bei Fragen gerne direkt bei uns!`;
      } else {
        nextStepContent.text = `Das Gericht hat einen Termin für Ihr Verfahren festgesetzt. Weitere Informationen können Sie dem Schreiben entnehmen, das Sie unter “Meine Fälle” finden. Melden Sie sich bei Fragen gerne direkt bei uns!`;
      }
      break;
    case STATUS_DECISION_MADE:
    case STATUS_COMPLETED:
      nextStepContent.header = "Ihr Fall ist abgeschlossen";
      nextStepContent.text =
        "Vielen Dank für Ihr Vertrauen. Wir wünschen Ihnen alles Gute und unterstützen Sie gerne auch zukünftig bei all Ihren Rechtsfragen!";
      break;
    default:
      break;
  }

  return nextStepContent;
};

const getButton = (traffic) => {
  let target = "";
  let text = "";

  switch (traffic.leadStatus) {
    case STATUS_CREATED:
      target = getCaseLink(traffic) + "/antrag";
      text = "Angaben vervollständigen";
      break;

    case STATUS_DATA_COMPLETED:
      target = getCaseLink(traffic) + "/antrag/dokumente";
      text = "Dokumente hochladen";
      break;

    case STATUS_DOCUMENTS_COMPLETE:
      target = getCaseLink(traffic) + "/antrag/vollmacht";
      text = "Zur Vollmacht";
      break;

    case STATUS_POWER_OF_ATTORNEY_GRANTED:
      if (traffic.paymentType === "private" && traffic.paidDate === null) {
        target = "/checkout?orderId=3_" + traffic.id;
        text = "zur Bezahlung";
        break;
      }
      if (!traffic.fineNoticeReceivedDate) {
        target = getCaseLink(traffic) + "/bussgeldbescheid-hochladen";
        text = "Bescheid hochladen";
        break;
      }
      return null;
    case STATUS_CHECKED:
    case STATUS_AUTHORIZED:
    case STATUS_INSURANCE_COVERAGE_REQUEST:
      if (!traffic.fineNoticeReceivedDate) {
        target = getCaseLink(traffic) + "/bussgeldbescheid-hochladen";
        text = "Bescheid hochladen";
        break;
      }
      return null;
    case STATUS_ACCESS_TO_RECORDS:
    case STATUS_EXTRAJUDICIAL_PAID:
      if (traffic.investigation.assessment === "revoke" && traffic.customerAppealRevocationApproved === null) {
        target = getCaseLink(traffic) + "/einspruch-ruecknahme";
        text = "Rücknahme bestätigen";
        break;
      }
      return null;

    case STATUS_TRIAL:
      target = getCaseLink(traffic) + "#documents";
      text = "Zum Dokument";
      break;

    case STATUS_AUTHORITY_ANSWERED:
      if (traffic.paymentType !== "private" || traffic.authorityDecision !== "court") {
        target = getCaseLink(traffic) + "#documents";
        text = "Zum Dokument";
        break;
      }
      if (traffic.trialWanted === null) {
        target = getCaseLink(traffic) + "/rueckmeldung-gerichtliches-verfahren";
        text = "Vorgehen festlegen";
        break;
      } else {
        return null;
      }

    case STATUS_TRIAL_DATE_SET:
      target = getCaseLink(traffic) + "#documents";
      text = "Zum Dokument";
      break;

    default:
      return null;
  }

  return (
    <Button component={HashLink} to={target} variant="contained" color="primary" fullWidth>
      {text}
    </Button>
  );
};

const getPercentage = (traffic) => {
  return (Math.max(1, traffic.leadStatus) / 13) * 10;
};

export const getStatusBoxConfigTraffic = (traffic) => {
  let statusBoxConfig = {
    header: "Es ist ein Fehler aufgetreten",
    text: "",
    ctaButton: null,
    productName: "",
    percentage: 100,
  };

  const ctaButton = getButton(traffic);
  const content = getContent(traffic);

  statusBoxConfig.header = content.header;
  statusBoxConfig.text = content.text;
  statusBoxConfig.ctaButton = ctaButton;
  statusBoxConfig.productName = getProductData(traffic.name, "label");
  statusBoxConfig.percentage = getPercentage(traffic);

  return statusBoxConfig;
};
